import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../css/trendDashboardComponent.css';
import { Typography } from '@rmwc/typography';
import { getFacilityRequest } from '../actions/userActions';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { TextField } from '@rmwc/textfield';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import $ from 'jquery';
import {
  clearMetrics,
  getTrendsRequest,
  printTrends,
  getGroupsRequest,
  queryLabResults,
  querySubtestReport,
  getPopup,
} from '../actions/metricsActions';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@rmwc/select';
import { Radio, RadioGroup } from '@material-ui/core';
import { List, ListItem } from '@rmwc/list';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';
import {
  getSubtestRequest,
  getPanTestRequest,
} from '../actions/resultsActions';
import Swal from 'sweetalert2';
import { patientNameList } from '../actions/reportActions';
import { GridCell, GridRow } from '@rmwc/grid';

//This component was switched with the MetricsComponent. The UI feature for the Metrics dashboard code is changed here.
class TrendDashboardComponent extends Component {
  constructor() {
    super();
    this.state = {
      trendStartDate: '',
      trendEndDate: '',
      facilities: [],
      patients: [],
      testList: [],
      panChecked: [],
      searchModalOpen: false,
      checked: [],
      testChecked: [],
      trends: [],
      facArray: [],
      rv: 'day range',
      testType: 'panel',
      facilitySel: '',
      selectAll: false,
      searchBy: '',
      groups: [],
      sliceStart: '',
      sliceEnd: '',
      title: '',
      start: '',
      end: '',
      panel: '',
      id: '',
      name: '',
      searchTerms: '',
      popupDialog: '',
      testName: '',
      popup: [],
      date1: '',
      date2: '',
    };
    this.clearChecks = this.clearChecks.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let patientList = [];
    props.patients.forEach((patient) => {
      var patientDOB = patient.patientDOB;
      patientList.push(patient);
    });
    let facilityList = [];
    props.facilities.forEach((fac) => {
      facilityList.push(fac);
    });
    let groupList = [];

    props.groups.forEach((group) => {
      groupList.push(group);
    });
    let dr = '';
    props.trends.forEach((date) => {
      dr = date.dateRange;
    });
    if (
      props.trends &&
      state.trends &&
      JSON.stringify(props.trends.sort()) !==
        JSON.stringify(state.trends.sort())
    ) {
      return {
        ...state,
        facilities: props.facilities,
        testList: props.testList,
        trends: props.trends,
        newData: true,
        patients: props.patients,
        patientList: patientList,
        facilityList: facilityList,
        groupList: groupList,
        dr: dr,
        searching: props.searching,
        popup: props.popup,
      };
      
    } else {
      return {
        ...state,
        facilities: props.facilities,
        testList: props.testList,
        newData: false,
        patients: props.patients,
        patientList: patientList,
        facilityList: facilityList,
        groupList: groupList,
        dr: dr,
        searching: props.searching,
        popup: props.popup,
      };
    }
  }

  componentDidMount() {
    this.setState({ searchModalOpen: true });
    this.props.getFacilities();
    this.props.patientNameList();
    this.props.getSubtests();
    this.props.getPanTests();
    this.props.getGroupsRequest();
    ChartJS.register(ArcElement, Tooltip, Legend, Title);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleType = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      testChecked: [],
      testCHK: [],
    });
  };

  search = () => {
    let body = {};
    let startDate;
    let endDate;
    let date2;
    let date;

    if (this.state.rv === 'month range' || this.state.rv === 'day range') {
      date = new Date(Date.parse(this.state.trendStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(Date.parse(this.state.trendEndDate));
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'month') {
      date = new Date(Date.parse(this.state.trendStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(date.toUTCString());
      date2.setUTCMonth(date2.getUTCMonth() + 1);
      endDate = date2.toISOString().slice(0, 10);
    } else if (this.state.rv === 'day') {
      date = new Date(Date.parse(this.state.trendStartDate));
      startDate = date.toISOString().slice(0, 10);
      date2 = new Date(date.toUTCString());
      date2.setUTCMonth(date2.getUTCMonth() + 1);
      endDate = date.toISOString().slice(0, 10);
    }
    body = {
      dateFrom: startDate,
      dateTo: endDate,
      facilities: [JSON.parse(this.state.checked)],
      tests: this.state.testChecked,
      searchType: this.state.searchBy,
      testType: this.state.testType,
      span: this.state.rv,
    };

    this.props.getTrends(body);
    this.setState({ searchModalOpen: false, searchTerms: body });
  };

  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  selectAll = (e) => {
    {
      this.state.testType === 'panel'
        ? this.props.panList.map((test) => {
            if (test.panelName !== null) {
              this.setState({ [test.panelName]: true });
              let testChecked = this.state.testChecked;
              if (!this.state[test.panelName]) {
                testChecked.push(test);
              } else {
                testChecked = testChecked.filter(
                  (check) => check.panelName !== test.panelName
                );
              }
              this.setState({
                [test.panelName]: !this.state[test.panelName],
                testChecked: testChecked,
              });
            }
            this.setState({ selectAll: !this.state.selectAll });
          })
        : this.props.testList.map((test) => {
            if (test.testName !== null) {
              this.setState({ [test.testName]: true });
              let testChecked = this.state.testChecked;
              if (!this.state[test.testName]) {
                testChecked.push(test);
              } else {
                testChecked = testChecked.filter(
                  (check) => check.testName !== test.testName
                );
              }
              this.setState({
                [test.testName]: !this.state[test.testName],
                testChecked: testChecked,
              });
            }
            this.setState({ selectAll: !this.state.selectAll });
          });
    }
  };
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('checkd*');
      this.setState({ testChecked: [] });

      if (!regex.test(key)) {
        this.setState({ [key]: false });
      }
      return null;
    });
  };

  render() {
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];

    let popupDialog = () => {
      return (
        <div id="popup">
          <Dialog
            id="popupDialog"
            open={this.state.popupDialog}
            onClose={(evt) => {
              this.setState({ popupDialog: false });
            }}
          >
            <DialogTitle
              id="reportTitle"
              style={{
                borderTop: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              {'Group Breakdown By Facility for ' + this.state.testName}
            </DialogTitle>

            <DialogContent
              style={{
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              <GridRow>
                <GridCell
                  style={{ left: '400px', position: 'relative', top: '10px' }}
                >
                  {this.state.date1 + ' - ' + this.state.date2}
                </GridCell>
              </GridRow>

              {this.state.popup !== null ? (
                Object.keys(this.state.popup).map((key) => {
                  console.log(key);
                  let fac = key;
                  let html = [];
                  let a = '';
                  let n = '';
                  let c = '';
                  html.push(
                    <div>
                      <hr style={{ height: '40px' }}></hr>

                      <GridRow>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '390px',
                            top: '-20px',
                          }}
                        >
                          {fac}
                        </GridCell>
                      </GridRow>
                      {/* <hr style={{ height:'50px' }}></hr> */}
                    </div>
                  );
                  Object.keys(this.state.popup[key]).map((test) => {
                    if (test === 'totalAbnormalTests') {
                      a = this.state.popup[key][test];
                    }
                    if (test === 'totalNormalTests') {
                      n = this.state.popup[key][test];
                    }
                    if (test === 'totalCriticalTests') {
                      c = this.state.popup[key][test];
                    }
                  });
                  html.push(
                    <GridRow>
                      {/* <GridCell>{fac}</GridCell> */}
                      <GridRow>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '280px',
                            width: '450px',
                          }}
                        >
                          {'Total Abnormal Tests: ' + a}
                        </GridCell>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '420px',
                            width: '450px',
                          }}
                        >
                          {'Total Normal Tests: ' + n}
                        </GridCell>
                        <GridCell
                          style={{
                            position: 'relative',
                            left: '420px',
                            width: '450px',
                          }}
                        >
                          {'Total Critical Tests: ' + c}
                        </GridCell>
                      </GridRow>
                    </GridRow>
                  );
                  return html;
                })
              ) : (
                <Typography>No results </Typography>
              )}
            </DialogContent>
            <DialogActions
              style={{
                borderBottom: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                color: 'dodgerblue',
              }}
            >
              <DialogButton
                icon="close"
                cancel
                outlined
                onClick={() => {
                  this.setState({
                    popupDialog: false,
                  });
                }}
              >
                Close
              </DialogButton>
            </DialogActions>
          </Dialog>
        </div>
      );
    };
    let searchingModal = () => {
      return (
        <div
          id="searchingDialog"
          style={{ paddingLeft: '44%', paddingTop: '10%' }}
        >
          <CircularProgress size={100}></CircularProgress>
        </div>
      );
    };
    let searchModal = () => {
      return (
        <Dialog
          id="metricSearchDialog"
          open={this.state.searchModalOpen}
          onOpen={() => {
            this.props.clearMetrics();
          }}
          onClose={() => {
            this.setState({ searchModalOpen: false, name: '', checked: [] });
          }}
          maxwidth="md"
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Metrics Analysis
          </DialogTitle>
          <DialogContent
            id="trendsDialogContent"
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
              width: '100%',
            }}
          >
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <Typography>Select metrics type:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  id="searchBy"
                  label="Search by:"
                  outlined
                  options={['Facility', 'Group', 'Patient', 'State']}
                  value={this.state.searchBy}
                  onChange={(event) => {
                    let value = event.currentTarget.value;
                    this.setState({}, () => {
                      this.setState({
                        searchBy: value,
                        checked: [],
                        trends: [],
                      });
                    });
                  }}
                ></Select>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                {this.state.searchBy === 'Patient' ? (
                  <Typography>Select Patient Name:</Typography>
                ) : this.state.searchBy === 'Group' ? (
                  <Typography>Select Group Name:</Typography>
                ) : this.state.searchBy === 'State' ? (
                  <Typography>Select State Name:</Typography>
                ) : (
                  <Typography>Select Facility Name:</Typography>
                )}
              </Grid>
              <Grid item xs={7}>
                {this.state.searchBy === 'Facility' ? (
                  <Select
                    id="facilitySelect"
                    label="Facility"
                    outlined
                    // native
                    // displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                        title: JSON.parse(event.currentTarget.value)
                          .facilityName,
                      });
                    }}
                    // labelId="Facility"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {this.state.facilityList.map((state) => (
                      <option
                        key={state.facilityID}
                        name={state.facilityName}
                        value={JSON.stringify(state)}
                      >
                        {state.facilityName}
                      </option>
                    ))}
                  </Select>
                ) : this.state.searchBy === 'Patient' ? (
                  <Select
                    id="patientSelect"
                    label="Patient"
                    outlined
                    // native
                    // displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                        title:
                          JSON.parse(event.currentTarget.value)
                            .patientFirstName +
                          ' ' +
                          JSON.parse(event.currentTarget.value).patientLastName,
                        id: JSON.parse(event.currentTarget.value).patientID,
                      });
                    }}
                    // labelId="Patient"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {this.state.patientList.map((state) => (
                      <option
                        key={state.patientID}
                        name={state.patientID}
                        value={JSON.stringify(state)}
                      >
                        {state.patientLastName + ', ' + state.patientFirstName}
                      </option>
                    ))}
                  </Select>
                ) : this.state.searchBy === 'Group' ? (
                  <Select
                    style={{
                      width: '100%',
                    }}
                    id="groupSelect"
                    label="Group"
                    outlined
                    // native
                    // displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                        title: JSON.parse(event.currentTarget.value).groupName,
                        id: JSON.parse(event.currentTarget.value).groupID,
                      });
                    }}
                    // labelId="Group"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {this.state.groupList.map((state) => (
                      <option
                        name={state.groupID}
                        value={JSON.stringify(state)}
                      >
                        {state.groupName}
                      </option>
                    ))}
                  </Select>
                ) : this.state.searchBy === 'State' ? (
                  <Select
                    style={{
                      width: '100%',
                    }}
                    id="stateSelect"
                    label="State"
                    outlined
                    // native
                    // displayEmpty
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        checked: event.currentTarget.value,
                        name: event.currentTarget.name,
                        title: JSON.parse(event.currentTarget.value),
                        id: JSON.parse(event.currentTarget.value),
                      });
                    }}
                    // labelId="State"
                    // inputProps={{
                    //   name: 'checked',
                    // }}
                  >
                    {states.map((state) => (
                      <option
                        key={state}
                        name={state}
                        value={JSON.stringify(state)}
                      >
                        {state}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    label="Select a search type"
                    outlined
                    // native
                    // displayEmpty
                  ></Select>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              alignContent="center"
              justifyContent="center"

              // style={{ width:'100%'}}
            >
              <Grid item xs={5}>
                <Typography style={{ top: '20px', left: '0px' }}>
                  Select Date Search:{' '}
                </Typography>
              </Grid>
              <Grid item xs={5} style={{ left: '-40px', position: 'relative' }}>
                <RadioGroup
                  value={this.state.rv}
                  name="rv"
                  onChange={this.handleChange}
                  row
                  style={{ left: '-120px', position: 'relative' }}
                >
                  <FormControlLabel
                    value="day range"
                    control={<Radio color="primary" />}
                    label="Day Range"
                  />
                  <FormControlLabel
                    value="month range"
                    control={<Radio color="primary" />}
                    label="Month Range"
                  />
                  <FormControlLabel
                    value="month"
                    control={<Radio color="primary" />}
                    label="Month"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={4}>
                {' '}
                {this.state.rv === 'month' ||
                this.state.rv === 'month range' ? (
                  <div id="trendStartDate">
                    <Typography style={{ left: '165px', position: 'relative' }}>
                      Start Month
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '165px' }}
                        outlined
                        id="trendStartDateCalender"
                        name="trendStartDate"
                        type="month"
                        value={this.state.trendStartDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : (
                  <div id="trendStartDate">
                    <Typography style={{ left: '165px', position: 'relative' }}>
                      Start Day
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '165px' }}
                        outlined
                        id="trendStartDateCalender"
                        name="trendStartDate"
                        type="date"
                        value={this.state.trendStartDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                )}
              </Grid>
              <Grid item xs={4}>
                {this.state.rv === 'month range' ? (
                  <div id="trendEndDate">
                    <Typography style={{ left: '75px', position: 'relative' }}>
                      End Month
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '75px' }}
                        outlined
                        id="trendEndDateCalender"
                        name="trendEndDate"
                        type="month"
                        value={this.state.trendEndDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : this.state.rv === 'day range' ? (
                  <div id="trendEndDate">
                    <Typography style={{ left: '75px', position: 'relative' }}>
                      End Day
                    </Typography>
                    <form noValidate>
                      <TextField
                        style={{ width: '70%', left: '75px' }}
                        outlined
                        id="trendEndDateCalender"
                        name="trendEndDate"
                        type="date"
                        value={this.state.trendEndDate}
                        onChange={this.handleChange}
                      />
                    </form>
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid>
              <Grid
                container
                spacing={4}
                alignContent="center"
                justifyContent="center"
              >
                <Grid item xs={5}>
                  <Typography
                    style={{ top: '0px', left: '0px', position: 'relative' }}
                  >
                    Select Panel or Test:{' '}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ left: '-150px', position: 'relative', top: '0px' }}
                >
                  <RadioGroup
                    value={this.state.testType}
                    name="testType"
                    onChange={(e) =>
                      this.setState({
                        [e.target.name]: e.target.value,
                        testChecked: [],
                      })
                    }
                    row
                  >
                    <FormControlLabel
                      value="panel"
                      control={<Radio color="primary" />}
                      label="Panel"
                    />
                    <FormControlLabel
                      value="subtest"
                      control={<Radio color="primary" />}
                      label="Test"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid
                  item
                  xs={9}
                  style={{
                    borderStyle: 'solid',
                    left: '0%',
                    position: 'relative',
                  }}
                >
                  {this.state.testType === 'panel' ? (
                    <Typography>Panels</Typography>
                  ) : (
                    <Typography>Tests</Typography>
                  )}
                  <List
                    id="testList"
                    dense={true}
                    style={{
                      height: '180px',
                      position: 'reletive',
                      overflow: 'auto',
                      width: '100%',
                      top: '20px',
                    }}
                  >
                    {this.state.testType === 'subtest' ? (
                      <ListItem style={{ color: 'black', width: '100%' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.selectAll}
                              onClick={(e) => this.selectAll(e)}
                              color="primary"
                            />
                          }
                          label="Check All"
                        />
                      </ListItem>
                    ) : null}

                    {this.props.testList.length > 0 &&
                    this.state.testType === 'subtest'
                      ? this.props.testList.map((test) => {
                          if (test.testName !== null) {
                            return (
                              <ListItem
                                style={{
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  color: 'black',
                                  width: '100%',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state[test.testName]
                                          ? this.state[test.testName]
                                          : false
                                      }
                                      onClick={() => {
                                        let testChecked =
                                          this.state.testChecked;
                                        if (!this.state[test.testName]) {
                                          testChecked.push(test);
                                        } else {
                                          testChecked = testChecked.filter(
                                            (check) =>
                                              check.testName !== test.testName
                                          );
                                        }
                                        this.setState({
                                          [test.testName]:
                                            !this.state[test.testName],
                                          testChecked: testChecked,
                                        });
                                      }}
                                      name={test.testName}
                                      color="primary"
                                    />
                                  }
                                  label={test.testName}
                                />
                              </ListItem>
                            );
                          }
                        })
                      : null}
                    {this.props.testList.length > 0 &&
                    this.state.testType === 'panel'
                      ? this.props.panList.map((test) => {
                          if (test.testName !== null) {
                            let testCHK = 'checkd' + test.panelName;

                            return (
                              <ListItem
                                style={{
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  color: 'black',
                                  width: '100%',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        this.state.testChecked.length !== 0 &&
                                        !this.state[testCHK]
                                      }
                                      checked={
                                        this.state[test.panelName]
                                          ? this.state[test.panelName]
                                          : false
                                      }
                                      onClick={() => {
                                        let testChecked =
                                          this.state.testChecked;
                                        if (!this.state[test.panelName]) {
                                          testChecked.push(test);
                                        } else {
                                          testChecked = testChecked.filter(
                                            (check) =>
                                              check.panelName !== test.panelName
                                          );
                                        }
                                        this.setState({
                                          [test.panelName]:
                                            !this.state[test.panelName],
                                          [testCHK]: !this.state[testCHK],

                                          testChecked: testChecked,
                                          panel: test.panelName,
                                        });
                                      }}
                                      name={test.panelName}
                                      color="primary"
                                    />
                                  }
                                  label={test.panelName}
                                />
                              </ListItem>
                            );
                          }
                        })
                      : null}

                    {this.props.panList.length <= 0 &&
                    this.state.testType === 'panel' ? (
                      <Typography variant="h2">No Panels</Typography>
                    ) : null}
                    {this.props.testList.length <= 0 &&
                    this.state.testType === 'subtest' ? (
                      <Typography variant="h2">No Tests</Typography>
                    ) : null}
                  </List>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={3}>
                  <Button
                    id="createMetricsButton"
                    variant="contained"
                    onClick={() => {
                      if (
                        this.state.rv === 'month range' &&
                        (this.isEmpty(this.state.trendStartDate) ||
                          this.isEmpty(this.state.trendEndDate) ||
                          this.isEmpty(this.state.checked) ||
                          this.isEmpty(this.state.testChecked) ||
                          this.isEmpty(this.state.searchBy))
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all fields.',
                        });
                      } else if (
                        this.state.rv === 'day range' &&
                        (this.isEmpty(this.state.trendStartDate) ||
                          this.isEmpty(this.state.trendEndDate) ||
                          this.isEmpty(this.state.checked) ||
                          this.isEmpty(this.state.testChecked) ||
                          this.isEmpty(this.state.searchBy))
                      ) {
                        Swal.fire({
                          icon: 'error',
                          text: 'Please fill out all fields.',
                        });
                      } else {
                        this.search();
                        if (
                          this.state.rv === 'month range' ||
                          this.state.rv === 'day range'
                        ) {
                          if (this.state.rv === 'month range') {
                            var date = new Date(
                              this.state.trendStartDate + 'T00:00-0800'
                            ).toLocaleDateString();
                            var today = new Date(
                              this.state.trendEndDate + 'T00:00-0800'
                            );
                            var lastDayOfMonth = new Date(
                              today.getFullYear(),
                              today.getMonth() + 1,
                              0
                            );
                            var date2 = lastDayOfMonth.toLocaleDateString();
                          } else {
                            var date = new Date(
                              this.state.trendStartDate + 'T00:00-0800'
                            ).toLocaleDateString();
                            var date2 = new Date(
                              this.state.trendEndDate + 'T00:00-0800'
                            ).toLocaleDateString();
                          }

                          this.setState({
                            start: date,
                            end: date2,
                            addUserDialogOpen: false,
                            editingUser: false,
                            sliceStart:
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(0, 4),
                            sliceEnd:
                              new Date(this.state.trendEndDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.trendEndDate)
                                .toISOString()
                                .slice(0, 4),
                          });
                        } else {
                          var date = new Date(
                            this.state.trendStartDate + 'T00:00-0800'
                          ).toLocaleDateString();
                          var today = new Date(
                            this.state.trendStartDate + 'T00:00-0800'
                          );
                          var lastDayOfMonth = new Date(
                            today.getFullYear(),
                            today.getMonth() + 1,
                            0
                          );
                          var date2 = lastDayOfMonth.toLocaleDateString();
                          this.setState({
                            addUserDialogOpen: false,
                            editingUser: false,
                            checked: [],
                            start: date,
                            end: date2,
                            sliceStart:
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(5, 7) +
                              '-' +
                              new Date(this.state.trendStartDate)
                                .toISOString()
                                .slice(0, 4),
                            sliceEnd: '',
                          });
                        }
                      }
                    }}
                  >
                    Generate Metrics
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              icon="close"
              // cancel
              outlined
              onClick={() => {
                this.setState({ searchModalOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let listType = () => {
      return (
        <FormControl>
          <Select
            id="facilitySelect"
            label="Facility"
            outlined
            // native
            // displayEmpty
            onChange={(event) => {
              this.setState({ checked: event.currentTarget.value });
            }}
            // labelId="Facility"
            // inputProps={{
            //   name: 'checked',
            // }}
          >
            {this.state.facilityList.map((state) => (
              <option name={state.facilityName} value={JSON.stringify(state)}>
                {state.facilityName}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    };

    let doughnutShop = () => {
      let blue = 'rgba(116, 195, 240, 0.47)';
      let orange = 'rgba(230, 91, 61, 0.47)';
      let red = 'rgba(247, 8, 12, 0.75)';
      let posStr = 'Abnormal';
      let negStr = 'Normal';
      let critStr = 'Critical';

      if (this.state.trends.length > 0) {
        let charts = null;
        if (this.state.searchBy === 'Facility') {
          this.state.trends.map((facility) => {
            let keys = Object.keys(facility.facilityData);
            charts = keys.map((test) => {
              console.log(test);
              const options = {
                cutoutPercentage: 65,
                responsive: true,
                position: 'relitive',
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                    onClick: function (event, legendItem, legend) {
                      const index = legendItem.index;
                      const ci = legend.chart;
                      let data = ci.data;
                      var ds = data.datasets[0];

                      if (legendItem.hidden === false) {
                        ci.hide(0, index);
                        ci.legend.legendItems[index].hidden = true;
                      } else {
                        ci.show(0, index);
                        ci.legend.legendItems[index].hidden = false;
                      }
                    },
                    labels: {
                      generateLabels: function (chart) {
                        let data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                          return data.labels.map(function (label, i) {
                            var meta = chart.getDatasetMeta(0);
                            var ds = data.datasets[0];
                            var arc = meta.data[i];
                            var color = '';
                            let value = '';

                            switch (label) {
                              case 'Abnormal':
                                value =
                                  facility.facilityData[ds.label]
                                    .totalAbnormalTests;
                                color = orange;
                                break;
                              case 'Critical':
                                value =
                                  facility.facilityData[ds.label]
                                    .totalCriticalTests;
                                color = red;
                                break;
                              default:
                                value =
                                  facility.facilityData[ds.label]
                                    .totalNormalTests;

                                color = blue;
                            }

                            let hidden = null;
                            if (
                              chart.legend.legendItems &&
                              chart.legend.legendItems.length > 0
                            ) {
                              hidden = chart.legend.legendItems[i].hidden;
                            } else {
                              hidden = false;
                            }

                            return {
                              text: label + ' : ' + value,
                              hidden: hidden,
                              fillStyle: color,
                              strokeStyle: color,
                              backgroundColor: color,
                              borderColor: color,
                              index: i,
                              dataIndex: i,
                            };
                          });
                        } else {
                          return [];
                        }
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: test,
                    font: {
                      size: 15,
                    },
                  },
                },
              };
              let testCopy = $.extend(facility.facilityData[test]);
              let pos = testCopy.totalAbnormalTests;
              let neg = testCopy.totalNormalTests;
              let crit = testCopy.totalCriticalTests;

              let data = {
                labels: [posStr, negStr, critStr],
                datasets: [
                  {
                    label: test,
                    data: [pos, neg, crit],
                    backgroundColor: [orange, blue, red],
                    borderColor: [orange, blue, red],
                  },
                ],
              };
              return (
                <Grid xs={2} style={{ height: '200px' }}>
                  <Doughnut
                    onClick={() => {
                      let start = '';
                      if (this.state.rv === 'month range') {
                        start = this.state.trendStartDate + '-01';
                        var end = new Date(
                          this.state.trendEndDate + 'T00:00-0800'
                        );
                        var lastDayOfMonth = new Date(
                          end.getFullYear(),
                          end.getMonth() + 1,
                          0
                        );
                        var date2 = lastDayOfMonth.toISOString().split('T')[0];
                      } else if (this.state.rv === 'month') {
                        start = this.state.trendStartDate + '-01';
                        var end = new Date(
                          this.state.trendStartDate + 'T00:00-0800'
                        );
                        var lastDayOfMonth = new Date(
                          end.getFullYear(),
                          end.getMonth() + 1,
                          0
                        );
                        var date2 = lastDayOfMonth.toISOString().split('T')[0];
                      } else {
                        start = this.state.trendStartDate;
                        date2 = this.state.trendEndDate;
                      }
                      this.props.querySubtestReport();
                      this.props.queryLabResults(
                        data.datasets[0].label,
                        this.state.title,
                        'facility',
                        start,
                        date2
                      );
                      this.props.history.push('/app/result');
                    }}
                    options={options}
                    data={data}
                  />
                </Grid>
              );
            });
          });
        } else if (this.state.searchBy === 'Patient') {
          this.state.trends.map((patient) => {
            let keys = Object.keys(patient.patientData);
            charts = keys.map((test) => {
              const options = {
                cutoutPercentage: 65,
                responsive: true,
                position: 'relitive',
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                    onClick: function (event, legendItem, legend) {
                      const index = legendItem.index;
                      const ci = legend.chart;
                      let data = ci.data;
                      var ds = data.datasets[0];

                      if (legendItem.hidden === false) {
                        ci.hide(0, index);
                        ci.legend.legendItems[index].hidden = true;
                      } else {
                        ci.show(0, index);
                        ci.legend.legendItems[index].hidden = false;
                      }
                    },
                    labels: {
                      generateLabels: function (chart) {
                        let data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                          return data.labels.map(function (label, i) {
                            var meta = chart.getDatasetMeta(0);
                            var ds = data.datasets[0];
                            var arc = meta.data[i];

                            var fill = ds.backgroundColor[i];
                            var stroke = ds.borderColor[i];
                            let value = '';

                            switch (label) {
                              case 'Abnormal':
                                value =
                                  patient.patientData[ds.label]
                                    .totalAbnormalTests;
                                break;
                              case 'Critical':
                                value =
                                  patient.patientData[ds.label]
                                    .totalCriticalTests;
                                break;
                              default:
                                value =
                                  patient.patientData[ds.label]
                                    .totalNormalTests;
                            }

                            let hidden = null;
                            if (
                              chart.legend.legendItems &&
                              chart.legend.legendItems.length > 0
                            ) {
                              hidden = chart.legend.legendItems[i].hidden;
                            } else {
                              hidden = false;
                            }

                            return {
                              text: label + ' : ' + value,
                              hidden: hidden,
                              fillStyle: fill,
                              strokeStyle: stroke,
                              index: i,
                              dataIndex: i,
                            };
                          });
                        } else {
                          return [];
                        }
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: test,
                    font: {
                      size: 15,
                    },
                  },
                },
              };

              let testCopy = $.extend(patient.patientData[test]);
              let pos = testCopy.totalAbnormalTests;
              let neg = testCopy.totalNormalTests;
              let crit = testCopy.totalCriticalTests;
              let data = {
                labels: [posStr, negStr, critStr],
                datasets: [
                  {
                    label: test,
                    data: [pos, neg, crit],
                    backgroundColor: [orange, blue, red],
                    borderColor: [orange, blue, red],
                  },
                ],
              };
              return (
                <Grid xs={2} style={{ height: '200px' }}>
                  <Doughnut
                    onClick={() => {
                      let start = '';
                      if (this.state.rv === 'month range') {
                        start = this.state.trendStartDate + '-01';
                        var end = new Date(
                          this.state.trendEndDate + 'T00:00-0800'
                        );
                        var lastDayOfMonth = new Date(
                          end.getFullYear(),
                          end.getMonth() + 1,
                          0
                        );
                        var date2 = lastDayOfMonth.toISOString().split('T')[0];
                      } else if (this.state.rv === 'month') {
                        start = this.state.trendStartDate + '-01';
                        var end = new Date(
                          this.state.trendStartDate + 'T00:00-0800'
                        );
                        var lastDayOfMonth = new Date(
                          end.getFullYear(),
                          end.getMonth() + 1,
                          0
                        );
                        var date2 = lastDayOfMonth.toISOString().split('T')[0];
                      } else {
                        start = this.state.trendStartDate;
                        date2 = this.state.trendEndDate;
                      }
                      this.props.querySubtestReport();
                      this.props.queryLabResults(
                        data.datasets[0].label,
                        this.state.id,
                        'patient',
                        start,
                        date2
                      );
                      this.props.history.push('/app/result');
                    }}
                    options={options}
                    data={data}
                  />
                </Grid>
              );
            });
          });
        } else if (this.state.searchBy === 'Group') {
          this.state.trends.map((group) => {
            let keys = Object.keys(group.patientData);
            charts = keys.map((test) => {
              const options = {
                cutoutPercentage: 65,
                responsive: true,
                position: 'relitive',
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                    onClick: function (event, legendItem, legend) {
                      const index = legendItem.index;
                      const ci = legend.chart;
                      let data = ci.data;
                      var ds = data.datasets[0];

                      if (legendItem.hidden === false) {
                        ci.hide(0, index);
                        ci.legend.legendItems[index].hidden = true;
                      } else {
                        ci.show(0, index);
                        ci.legend.legendItems[index].hidden = false;
                      }
                    },
                    labels: {
                      generateLabels: function (chart) {
                        let data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                          return data.labels.map(function (label, i) {
                            var meta = chart.getDatasetMeta(0);
                            var ds = data.datasets[0];
                            var arc = meta.data[i];

                            var fill = ds.backgroundColor[i];
                            var stroke = ds.borderColor[i];
                            let value = '';
                            switch (label) {
                              case 'Abnormal':
                                value =
                                  group.patientData[ds.label]
                                    .totalAbnormalTests;
                                break;
                              case 'Critical':
                                value =
                                  group.patientData[ds.label]
                                    .totalCriticalTests;
                                break;
                              default:
                                value =
                                  group.patientData[ds.label].totalNormalTests;
                            }

                            let hidden = null;
                            if (
                              chart.legend.legendItems &&
                              chart.legend.legendItems.length > 0
                            ) {
                              hidden = chart.legend.legendItems[i].hidden;
                            } else {
                              hidden = false;
                            }

                            return {
                              text: label + ' : ' + value,
                              hidden: hidden,
                              fillStyle: fill,
                              strokeStyle: stroke,
                              index: i,
                              dataIndex: i,
                            };
                          });
                        } else {
                          return [];
                        }
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: test,
                    font: {
                      size: 15,
                    },
                  },
                },
              };

              let testCopy = $.extend(group.patientData[test]);
              let pos = testCopy.totalAbnormalTests;
              let neg = testCopy.totalNormalTests;
              let crit = testCopy.totalCriticalTests;
              let data = {
                labels: [posStr, negStr, critStr],
                datasets: [
                  {
                    label: test,
                    data: [pos, neg, crit],
                    backgroundColor: [orange, blue, red],
                    borderColor: [orange, blue, red],
                  },
                ],
              };
              return (
                <Grid xs={2} style={{ height: '200px' }}>
                  <Doughnut
                    onClick={() => {
                      var d = new Date(
                        this.state.searchTerms.dateFrom + 'T00:00:00'
                      );
                      var d2 = new Date(
                        this.state.searchTerms.dateTo + 'T00:00:00'
                      );
                      var date1 = '';
                      var date2 = '';
                      var lastDayOfMonth = '';

                      if (this.state.searchTerms.span === 'month') {
                        lastDayOfMonth = new Date(
                          d.getFullYear(),
                          d.getMonth() + 1,
                          0
                        );
                        date1 =
                          d.getMonth() +
                          1 +
                          '/' +
                          d.getDate() +
                          '/' +
                          d.getFullYear();
                        date2 =
                          lastDayOfMonth.getMonth() +
                          1 +
                          '/' +
                          lastDayOfMonth.getDate() +
                          '/' +
                          lastDayOfMonth.getFullYear();
                      } else if (this.state.searchTerms.span === 'day range') {
                        date1 =
                          d.getMonth() +
                          1 +
                          '/' +
                          d.getDate() +
                          '/' +
                          d.getFullYear();
                        date2 =
                          d2.getMonth() +
                          1 +
                          '/' +
                          d2.getDate() +
                          '/' +
                          d2.getFullYear();
                      } else if (
                        this.state.searchTerms.span === 'month range'
                      ) {
                        lastDayOfMonth = new Date(
                          d2.getFullYear(),
                          d2.getMonth() + 1,
                          0
                        );
                        date1 =
                          d.getMonth() +
                          1 +
                          '/' +
                          d.getDate() +
                          '/' +
                          d.getFullYear();
                        date2 =
                          lastDayOfMonth.getMonth() +
                          1 +
                          '/' +
                          lastDayOfMonth.getDate() +
                          '/' +
                          lastDayOfMonth.getFullYear();
                      }

                      this.setState({
                        popupDialog: true,
                        testName: data.datasets[0].label,
                        date1: date1,
                        date2: date2,
                      });

                      this.props.getPopup(
                        this.state.searchTerms,
                        data.datasets[0].label
                      );
                    }}
                    options={options}
                    data={data}
                  />
                </Grid>
              );
            });
          });
        } else if (this.state.searchBy === 'State') {
          this.state.trends.map((group) => {
            let keys = Object.keys(group.facilityData);
            charts = keys.map((test) => {
              const options = {
                cutoutPercentage: 65,
                responsive: true,
                position: 'relitive',
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                    onClick: function (event, legendItem, legend) {
                      const index = legendItem.index;
                      const ci = legend.chart;
                      let data = ci.data;
                      var ds = data.datasets[0];

                      if (legendItem.hidden === false) {
                        ci.hide(0, index);
                        ci.legend.legendItems[index].hidden = true;
                      } else {
                        ci.show(0, index);
                        ci.legend.legendItems[index].hidden = false;
                      }
                    },
                    labels: {
                      generateLabels: function (chart) {
                        let data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                          return data.labels.map(function (label, i) {
                            var meta = chart.getDatasetMeta(0);
                            var ds = data.datasets[0];
                            var arc = meta.data[i];

                            var fill = ds.backgroundColor[i];
                            var stroke = ds.borderColor[i];
                            let value = '';
                            switch (label) {
                              case 'Abnormal':
                                value =
                                  group.facilityData[ds.label]
                                    .totalAbnormalTests;
                                break;
                              case 'Critical':
                                value =
                                  group.facilityData[ds.label]
                                    .totalCriticalTests;
                                break;
                              default:
                                value =
                                  group.facilityData[ds.label].totalNormalTests;
                            }

                            let hidden = null;
                            if (
                              chart.legend.legendItems &&
                              chart.legend.legendItems.length > 0
                            ) {
                              hidden = chart.legend.legendItems[i].hidden;
                            } else {
                              hidden = false;
                            }

                            return {
                              text: label + ' : ' + value,
                              hidden: hidden,
                              fillStyle: fill,
                              strokeStyle: stroke,
                              index: i,
                              dataIndex: i,
                            };
                          });
                        } else {
                          return [];
                        }
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: test,
                    font: {
                      size: 15,
                    },
                  },
                },
              };

              let testCopy = $.extend(group.facilityData[test]);
              let pos = testCopy.totalAbnormalTests;
              let neg = testCopy.totalNormalTests;
              let crit = testCopy.totalCriticalTests;

              let data = {
                labels: [posStr, negStr, critStr],
                datasets: [
                  {
                    label: test,
                    data: [pos, neg, crit],
                    backgroundColor: [orange, blue, red],
                    borderColor: [orange, blue, red],
                  },
                ],
              };
              return (
                <Grid xs={2} style={{ height: '200px' }}>
                  <Doughnut
                    onClick={() => {
                      let start = '';
                      if (this.state.rv === 'month range') {
                        start = this.state.trendStartDate + '-01';
                        var end = new Date(
                          this.state.trendEndDate + 'T00:00-0800'
                        );
                        var lastDayOfMonth = new Date(
                          end.getFullYear(),
                          end.getMonth() + 1,
                          0
                        );
                        var date2 = lastDayOfMonth.toISOString().split('T')[0];
                      } else if (this.state.rv === 'month') {
                        start = this.state.trendStartDate + '-01';
                        var end = new Date(
                          this.state.trendStartDate + 'T00:00-0800'
                        );
                        var lastDayOfMonth = new Date(
                          end.getFullYear(),
                          end.getMonth() + 1,
                          0
                        );
                        var date2 = lastDayOfMonth.toISOString().split('T')[0];
                      } else {
                        start = this.state.trendStartDate;
                        date2 = this.state.trendEndDate;
                      }
                      this.props.querySubtestReport();
                      this.props.queryLabResults(
                        data.datasets[0].label,
                        this.state.id,
                        'state',
                        start,
                        date2
                      );
                      this.props.history.push('/app/result');
                    }}
                    options={options}
                    data={data}
                  />
                </Grid>
              );
            });
          });
        }

        return charts;
      }
    };

    return (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <div style={{ textAlign: 'center', color: 'dodgerblue' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography use="headline4">Metrics Analysis </Typography>
          </div>
        </div>

        {this.state.start !== '' && this.state.end !== '' ? (
          <div id="title" style={{ textAlign: 'center' }}>
            <div>
              <Typography use="headline6">{this.state.title}</Typography>
            </div>
            <div>
              <Typography use="headline6">{this.state.panel}</Typography>
            </div>
            <div>
              {' '}
              <Typography use="headline6">
                {this.state.start !== false && this.state.end !== false
                  ? this.state.start + ' - ' + this.state.end
                  : null}
              </Typography>
            </div>
            <div style={{ height: '40px' }}></div>
          </div>
        ) : null}

        <Grid container spacing={4}>
          {this.state.trends.length > 0 ? (
            doughnutShop()
          ) : (
            <Typography
              id="noTrends"
              style={{ left: '43%', position: 'absolute', top: '50%' }}
            >
              No Metrics to display
            </Typography>
          )}
        </Grid>

        <div>
          <div>
            <Button
              id="addButton"
              icon={<SearchIcon />}
              label="Search Trends"
              onClick={() => {
                this.props.clearMetrics();
                this.clearChecks();
                this.setState({
                  searchModalOpen: !this.state.searchModalOpen,
                  trends: [],
                  searchBy: '',
                  testChecked: [],
                  metricStartDate: [],
                  metricEndDate: [],
                  checked: [],
                  name: '',
                  rv: 'day range',
                  testType: 'panel',
                  sliceStart: '',
                  sliceEnd: '',
                });
              }}
            >
              Search Metrics
            </Button>
          </div>
          <div>
            <Button
              id="mergeButton"
              onClick={() => {
                if (this.isEmpty(this.state.trends)) {
                  Swal.fire({
                    icon: 'warning',
                    text: 'Please create a metrics search',
                  });
                } else {
                  let json = this.state.trends[0];
                  if (this.state.sliceEnd !== '') {
                    json['dateRange'] =
                      this.state.sliceStart + ' to ' + this.state.sliceEnd;
                  } else {
                    json['dateRange'] = this.state.sliceStart;
                  }
                  this.props.printTrends(json);
                }
              }}
            >
              Create PDF Report
            </Button>
          </div>
        </div>
        {this.state.searching === true ? searchingModal() : null}
        {searchModal()}
        {this.props.popupLoadingSuccess ? popupDialog() : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  facilities: state.metrics.facilities,
  trends: state.metrics.trends,
  testList: state.metrics.testList,
  panList: state.metrics.panTestList,
  patients: state.report.patients,
  groups: state.metrics.groups,
  searching: state.metrics.searching,
  popup: state.metrics.popup,
  popupLoading: state.metrics.popupLoading,
  popupLoadingSuccess: state.metrics.popupLoadingSuccess,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFacilities: () => dispatch(getFacilityRequest()),
    getSubtests: () => dispatch(getSubtestRequest()),
    getPanTests: () => dispatch(getPanTestRequest('')),
    getTrends: (body) => dispatch(getTrendsRequest(body)),
    getPopup: (body, test) => dispatch(getPopup(body, test)),
    clearMetrics: () => dispatch(clearMetrics()),
    printTrends: (body) => dispatch(printTrends(body)),
    patientNameList: () => dispatch(patientNameList()),
    getGroupsRequest: () => dispatch(getGroupsRequest()),
    queryLabResults: (subtest, id, type, start, end) =>
      dispatch(queryLabResults(subtest, id, type, start, end)),
    querySubtestReport: () => dispatch(querySubtestReport()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrendDashboardComponent);
