import React from 'react';
import { connect } from 'react-redux';
// import { TextField } from '@rmwc/textfield';
import TextField from '@material-ui/core/TextField';

import { Button } from '@rmwc/button';
import { login, getDatabasesRequest } from '../actions/loginActions';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { Typography } from '@rmwc/typography';
import tsalta_logo from '../images/TSalta_Solutions_Transparent_SuperFrench.png';
import '../css/LoginComponent.css';
import Grid from '@material-ui/core/Grid';



class LoginComponent extends React.Component {
  /*
   *Contains components for the login page.
   */

  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      dbList: [],
      db: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { username, password, db } = this.state;
    if (username && password && db) {
      this.props.login(username, password, db);
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.cookies.get('jwt')) {
      this.props.history.push('/app/dashboard');
    }
  }
  componentDidMount() {
    this.props.getDBS();
  }
  componentDidUpdate() {
    if (this.props.jwt) {
      this.props.history.push('/app/dashboard');
    }
    if (this.state.dbList.length !== this.props.dbList.length) {
      this.setState({ dbList: this.props.dbList });
    }
  }


  render() {
    return (

      <div id='loginDivOuter'>
        {/* <div style={{ backgroundColor: '#b4e0fa', overflowX: 'clip', height: '950px', overflowY: 'clip' }}> */}

        <form onSubmit={this.handleSubmit}>

          <Grid
            container
            spacing={5}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={5} style={{ top: '250px', position: 'relative' }}>
              <img
                src={tsalta_logo}
                alt="Tsalta Logo"
                id="tsaltaLogo"
                style={{ backgroundColor: 'clear', width: '220px' }}
              />

            </Grid>

          </Grid>

          <Grid
            container
            spacing={5}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={5} style={{ top: '310px', left: '150px', position: 'relative' }}>
              <TextField
                id='userTextField'
                style={{ position: 'absolute', top: '40%', width: '50%' }}
                inputStyle={{
                  textAlign: 'right',
                }}
                label="Username"
                variant="outlined"
                required
                trailingIcon="account_box"
                name="username"
                type="text"
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={5}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={5} style={{ top: '355px', left: '150px', position: 'relative' }}>
              <TextField
                variant="outlined"
                style={{ position: 'absolute', top: '40%', width: '50%' }}
                label="Password"
                required
                trailingIcon="lock"
                name="password"
                type="password"
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={5}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={5} style={{ top: '395px', left: '150px', position: 'relative' }}>
              <TextField
                variant="outlined"
                style={{ position: 'absolute', top: '55%', width: '50%' }}
                label="Database"
                required
                name="db"
                trailingIcon="computer"
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container
            spacing={5}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={5} style={{ top: '440px', left: '150px', position: 'relative' }}>
              <Button
                elevated
                style={{
                  backgroundColor: 'navy',
                  color: 'white',
                  position: 'absolute',
                  top: '65%',
                  width: '50%'
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>

      </div>





      // <div id="loginDivOuter">
      //   <div id="loginDivInner">
      // <img
      //   src={tsalta_logo}
      //   alt="Tsalta Logo"
      //   id="tsaltaLogo"
      //   style={{ backgroundColor: 'clear' }}
      // />
      //     <form onSubmit={this.handleSubmit}>
      //       <div>
      //         <Typography use="caption">
      //           <span hidden={this.props.loginSuccess} id="invalidCred">
      //             Invalid username and/or password.
      //           </span>
      //         </Typography>
      //       </div>
      // <TextField
      //   style={{ position: 'absolute', top: '25%' }}
      //   inputStyle={{
      //     textAlign: 'right',
      //   }}
      //   label="Username"
      //   outlined
      //   required
      //   trailingIcon="account_box"
      //   name="username"
      //   type="text"
      //   onChange={this.handleChange}
      // />
      // <TextField
      //   outlined
      //   style={{ position: 'absolute', top: '40%' }}
      //   label="Password"
      //   required
      //   trailingIcon="lock"
      //   name="password"
      //   type="password"
      //   onChange={this.handleChange}
      // />

      // <TextField
      //   outlined
      //   style={{ position: 'absolute', top: '55%' }}
      //   label="Database"
      //   required
      //   name="db"
      //   onChange={this.handleChange}
      // />
      //       <div
      //         style={{
      //           paddingLeft: '5%',
      //           position: 'absolute',
      //           top: '55%',
      //           width: '100%',
      //         }}
      //       ></div>
      // <Button
      //   elevated
      //   style={{
      //     backgroundColor: 'navy',
      //     color: 'white',
      //     position: 'absolute',
      //     top: '65%',
      //   }}
      // >
      //   Login
      // </Button>
      //     </form>
      //   </div>
      //   <div></div>
      // </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, db) => dispatch(login(username, password, db)),
    getDBS: () => dispatch(getDatabasesRequest()),
  };
};

const mapStateToProps = (state) => {
  return {
    jwt: state.login.jwt,
    loginSuccess: state.login.success,
    dbList: state.login.dbList,
  };
};

LoginComponent.propTypes = {
  login: PropTypes.func.isRequired,
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
);
